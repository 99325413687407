<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="text-2xl">
          Tutoriais Plataforma Strong Expert
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="tutorial in tutorials"
        :key="tutorial.title"
        cols="12"
        xs="12"
        sm="6"
        md="4"
      >
        <v-card outlined>
          <iframe
            :src="tutorial.video"
            :title="tutorial.title"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            height="210"
            width="100%"
          />

          <v-card-title>{{ tutorial.title }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('app', [
      'tutorials',
    ]),
  },
}
</script>
